import React, { FunctionComponent } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'
import LoadingPage from '@/components/loading-page'
import useAuthorization from '@/hooks/use-authorization/use-authorization'

const Home: FunctionComponent = () => {
  const [query] = useQueryParams({
    redirect: StringParam,
  })
  const redirectUrl = query.redirect
  const { isAuthorizing } = useAuthorization({
    redirectUrl: redirectUrl || undefined,
  })

  if (!isAuthorizing) {
    return null
  }

  return <LoadingPage />
}

export default Home
